.footer {
  background-color: $body-background-color-dark;
}

.copyright {
  a {
    color: $feature-color-dark;
  }
}

#busuanzi_container_site_pv,
#busuanzi_container_site_uv {
  color: $text-color-minor-dark;
}
