// You can change the following settings by yourself :-)

:root {
  --base-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial,
    'PingFang SC', 'Hiragino Sans GB', STHeiti, 'Microsoft YaHei',
    'Microsoft JhengHei', 'Source Han Sans SC', 'Noto Sans CJK SC',
    'Source Han Sans CN', 'Noto Sans SC', 'Source Han Sans TC',
    'Noto Sans CJK TC', 'WenQuanYi Micro Hei', SimSun, sans-serif;
}

// ========== DIY ========== //
$feature-color: #f75357;
$feature-color-dark: #f75357; // Feature color in dark mode
$code-theme: 'atom-one-dark'; // atom-one-dark or atom-one-light

// ========== global variables ========== //
$body-background-color: #fff;
$foreground-color: #fdfdfd;
$body-background-color-dark: #121212;
$foreground-color-dark: #181818;
$base-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Arial,
  'PingFang SC', 'Hiragino Sans GB', STHeiti, 'Microsoft YaHei',
  'Microsoft JhengHei', 'Source Han Sans SC', 'Noto Sans CJK SC',
  'Source Han Sans CN', 'Noto Sans SC', 'Source Han Sans TC', 'Noto Sans CJK TC',
  'WenQuanYi Micro Hei', SimSun, sans-serif;
$feature-font-family: 'Oswald-Regular', $base-font-family;
$base-font-size: 16px !default;

// ========== CSS Variables ========== //
$base-font-family: var(--base-font-family);

// ========== common ========== //
$container-margin-top: 3rem;
$selection-background-color: $feature-color;
$selection-background-color-dark: $feature-color-dark;
$selection-text-color: #eee;
$selection-text-color-dark: snow;

// ========= dark mode ========= //
$text-color-primary-dark: rgba(255, 255, 255, 0.87);
$text-color-secondary-dark: rgba(255, 255, 255, 0.6);
$text-color-minor-dark: rgba(255, 255, 255, 0.38);
$transition-animation: all 0.5s ease-out;

// ========== iconfont ========== //
@font-face {
  font-family: 'iconfont-archer'; /* project id 327081 */
  src: url('//at.alicdn.com/t/font_327081_s1wbjxwfu9c.eot');
  src:
    url('//at.alicdn.com/t/font_327081_s1wbjxwfu9c.eot?#iefix')
      format('embedded-opentype'),
    url('//at.alicdn.com/t/font_327081_s1wbjxwfu9c.woff') format('woff'),
    url('//at.alicdn.com/t/font_327081_s1wbjxwfu9c.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_327081_s1wbjxwfu9c.svg#iconfont-archer')
      format('svg');
}

.iconfont-archer {
  font-family: 'iconfont-archer' !important;
  font-size: 1rem;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ========== header ========== //
$header-height: 3.5rem;
$header-height-mobile: 3rem;
$header-border-top-width: 3px;
$header-border-top-color: rgba(0, 0, 0, 0.75);
$header-border-top-color-dark: rgba(0, 0, 0, 0.75);
$header-banner-text-color: #333;
$header-banner-text-color-dark: $text-color-primary-dark;

// ========= sidebar ========= //
$sidebar-width: 400px;

// ========== profile ========== //
$profile-width: 200px;

// ========== index ========== //
$index-post-link-color: #1f1f1f;
$index-post-link-color-dark: #ccc;
$index-post-tag-color: #aaa;
$index-post-tag-color-dark: $text-color-minor-dark;

// ===== abstract content ===== //
$abstract-content-title-color: #333;
$abstract-content-title-color-dark: $text-color-primary-dark;
$abstract-content-text-color: #585858;
$abstract-content-text-color-dark: $text-color-secondary-dark;
$abstract-post-meta-color: #aaa;
$abstract-post-meta-color-dark: $text-color-minor-dark;

// ========== post ========== //
$post-content-color: #23394d;
$post-content-color-dark: $text-color-primary-dark;

// ========== social ========== //
$social-iconfont-color: #777;
$social-iconfont-color-dark: #777;

// ========== code ========== //
$code-color-map: (
  'atom-one-light': (
    base: #fafafa,
    mono-1: #383a42,
    mono-2: #686b77,
    mono-3: #a0a1a7,
    hue-1: #0184bb,
    hue-2: #4078f2,
    hue-3: #a626a4,
    hue-4: #50a14f,
    hue-5: #e45649,
    hue-5-2: #c91243,
    hue-6: #986801,
    hue-6-2: #c18401,
  ),
  'atom-one-dark': (
    base: #282c34,
    mono-1: #abb2bf,
    mono-2: #818896,
    mono-3: #5c6370,
    hue-1: #56b6c2,
    hue-2: #61aeee,
    hue-3: #c678dd,
    hue-4: #98c379,
    hue-5: #e06c75,
    hue-5-2: #be5046,
    hue-6: #d19a66,
    hue-6-2: #e6c07b,
  ),
);
@if not(map-has-key($code-color-map, $code-theme)) {
  $code-theme: 'atom-one-dark';
}
$theme-colors: map-get($code-color-map, $code-theme);

// ========== algolia-search ========== //
$algolia-search-padding-horiz: 20px;
