// ========== hover ========== //
@mixin hover-line($height: 2px, $width: 98%, $color: $feature-color) {
  &::after {
    content: '';
    position: absolute;
    left: calc((100% - #{$width}) / 2);
    bottom: -15%;
    transition: all 0.15s ease-in;
    width: $width;
    height: $height;
    transform: translate(0, 0);
    background-color: $color;
    opacity: 0;
  }
  &:hover::after {
    transform: translate(0, -2px);
    opacity: 1;
  }
}

// ========== social style ========== //
@mixin social($scaleSize, $spanMargin) {
  .iconfont-archer {
    display: inline-block;
    text-align: center;
  }
  .iconfont-archer::before {
    display: inline-block;
    color: $social-iconfont-color;
    font-size: 3rem * $scaleSize;
    margin: 0 $spanMargin 0 0;
  }
  .iconfont-archer:last-of-child::before {
    margin: 0;
  }
  .email {
    top: 4px * $scaleSize;
    &::before {
      content: '\e642';
    }
    &:hover::before {
      color: #0cb49d;
    }
  }
  .github {
    &::before {
      font-size: 3.2rem * $scaleSize;
      content: '\e69f';
    }
    &:hover::before {
      color: #24292e;
    }
  }
  .wechat {
    top: 4px * $scaleSize;
    &::before {
      font-size: 2.8rem * $scaleSize;
      content: '\ec7d';
    }
    &:hover::before {
      color: #42ae3c;
    }
  }
  .qq {
    top: 4px * $scaleSize;
    &::before {
      font-size: 2.8rem * $scaleSize;
      content: '\e61d';
    }
    &:hover::before {
      color: #4f4e71;
    }
  }
  .telegram {
    top: 2.5px * $scaleSize;
    &::before {
      font-size: 2.6rem * $scaleSize;
      content: '\e731';
    }
    &:hover::before {
      color: #36a1d3;
    }
  }
  .weibo {
    &::before {
      font-size: 3.2rem * $scaleSize;
      content: '\e619';
    }
    &:hover::before {
      color: #d52a2a;
    }
  }
  .zhihu {
    top: 3px * $scaleSize;
    &::before {
      font-size: 2.7rem * $scaleSize;
      content: '\e61a';
    }
    &:hover::before {
      color: #0f88eb;
    }
  }
  .douban {
    top: 2px * $scaleSize;
    &::before {
      font-size: 2.5rem * $scaleSize;
      content: '\e605';
    }
    &:hover::before {
      color: #007711;
    }
  }
  .facebook {
    top: 5px * $scaleSize;
    &::before {
      font-size: 3rem * $scaleSize;
      content: '\e67a';
    }
    &:hover::before {
      color: #3b5998;
    }
  }
  .twitter {
    top: 2px * $scaleSize;
    &::before {
      content: '\e634';
    }
    &:hover::before {
      color: #1da1f2;
    }
  }
  .instagram {
    top: 4px * $scaleSize;
    &::before {
      content: '\e79d';
      font-size: 2.8rem * $scaleSize;
    }
    &:hover::before {
      color: #8042b6;
    }
  }
  .stack-overflow {
    top: 1px * $scaleSize;
    &::before {
      font-size: 2.3rem * $scaleSize;
      content: '\e80b';
    }
    &:hover::before {
      color: #f37f23;
    }
  }
  .segmentFault {
    top: 4px * $scaleSize;
    &::before {
      font-size: 3rem * $scaleSize;
      content: '\e60c';
    }
    &:hover::before {
      color: #f37f23;
    }
  }
  .juejin {
    top: 1px * $scaleSize;
    &::before {
      font-size: 2.7rem * $scaleSize;
      content: '\e60b';
    }
    &:hover::before {
      color: #f37f23;
    }
  }
  .v2ex {
    &::before {
      font-size: 2.2rem * $scaleSize;
      content: '\e663';
    }
    &:hover::before {
      color: #171718;
    }
  }
  .bilibili {
    top: 6px * $scaleSize;
    &::before {
      font-size: 3.5rem * $scaleSize;
      content: '\e64e';
    }
    &:hover::before {
      color: #2cb2f9;
    }
  }
  .linkedin {
    top: -1px * $scaleSize;
    &::before {
      font-size: 2.5rem * $scaleSize;
      content: '\e695';
    }
    &:hover::before {
      color: #0077b5;
    }
  }
  .steam {
    top: 2px * $scaleSize;
    &::before {
      font-size: 2.5rem * $scaleSize;
      content: '\f23b';
    }
    &:hover::before {
      color: #010101;
    }
  }
  .others {
    top: -1px * $scaleSize;
    &::before {
      font-size: 2.2rem * $scaleSize;
      content: '\e64a';
    }
    &:hover::before {
      color: #8090b0;
    }
  }
  .rss {
    &::before {
      font-size: 2.1rem * $scaleSize;
      content: '\e652';
    }
    &:hover::before {
      color: #e53935;
    }
  }
}
