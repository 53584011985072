body {
  background-color: $body-background-color-dark;
}

.background-holder {
  background-color: $body-background-color-dark;
}

.container {
  background-color: $body-background-color-dark;
}

::selection {
  background: $selection-background-color-dark;
  color: $selection-text-color-dark;
}

.note {
  background-color: #1e1e1e !important;
}
