.profile {
  color: $social-iconfont-color-dark;
  border-color: rgba(255, 255, 255, 0.1);
}

.profile-avatar {
  border-color: rgba(255, 255, 255, 0.1);
}

.profile-name {
  color: #ddd;
}

.profile-social {
  border-color: rgba(255, 255, 255, 0.1);
}

.friends {
  border-color: rgba(255, 255, 255, 0.1);

  a {
    color: $social-iconfont-color-dark;
    &:hover {
      color: $feature-color-dark;
    }
  }
}

.profile-link-item {
  border-color: rgba(255, 255, 255, 0.1);

  a {
    color: $social-iconfont-color-dark;
    &:hover {
      color: $feature-color-dark;
    }
  }
}
