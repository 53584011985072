.read-progress {
  background-color: $text-color-secondary-dark;
}

.read-progress-feature {
  background-color: $feature-color-dark;
}

header {
  .banner {
    border-bottom-color: $text-color-primary-dark;
    background-color: rgba(0, 0, 0, 0.8);

    .blog-title {
      a {
        color: $header-banner-text-color-dark;
        &:hover {
          color: $feature-color-dark;
        }
      }
    }

    .post-title {
      a {
        color: $header-banner-text-color-dark;
      }
    }
  }
}

.header-sidebar-menu {
  border-color: $body-background-color;
  color: $body-background-color;

  &.header-sidebar-menu-active {
    background-color: $body-background-color;
    color: #000;
  }
}

.header-sidebar-menu-rounded {
  &.header-sidebar-menu-active {
    background-color: transparent;
    color: $feature-color-dark;
  }
}

.header-sidebar-menu-black {
  color: $text-color-primary-dark;
  background-color: $body-background-color-dark;
  border-color: $text-color-primary-dark;

  &.header-sidebar-menu-active {
    background-color: $text-color-primary-dark;
    color: $body-background-color-dark;
  }
}

@media not all and (hover: none) {
  .header-sidebar-menu:hover {
    background-color: $body-background-color;
    color: #000;
  }

  .header-sidebar-menu-rounded:hover {
    background-color: transparent;
    color: $feature-color-dark;
  }

  .header-sidebar-menu-black:hover {
    background-color: $text-color-primary-dark;
    color: $body-background-color-dark;
  }
}
