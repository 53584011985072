.anchorjs-archer {
  color: $feature-color-dark;
}

.article-entry {
  background-color: $foreground-color-dark;
}

.abstract-content,
.article-entry {
  color: $post-content-color-dark;
  blockquote {
    background-color: #1e1e1e;
    border-left-color: $feature-color-dark;
  }
  .table-container table,
  > table {
    border-color: #1e1e1e;
    box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.125);
    thead {
      tr {
        background: #1e1e1e;
      }
    }
    tbody {
      tr:hover {
        background: #242424;
      }
    }
    td,
    th {
      border-color: #2a2a2a;
    }
  }
  a {
    color: $feature-color-dark;
    &:hover {
      border-bottom-color: $feature-color-dark;
    }
  }
}

.license-wrapper {
  background-color: $foreground-color-dark;
}

.license-wrapper {
  color: $post-content-color-dark;
  a {
    color: $feature-color-dark;
  }
}

.toc-wrapper {
  .toc-active {
    color: $feature-color-dark;
  }
  a {
    color: #777;
  }
  a:hover {
    color: $feature-color-dark;
  }
}

.toc-catalog {
  &:hover {
    color: $feature-color-dark;
  }
}

.post-paginator {
  .nextTitle,
  .prevTitle {
    color: $text-color-minor-dark;
    &:hover {
      color: $feature-color-dark;
    }
  }

  .nextSlogan,
  .prevSlogan {
    color: $text-color-secondary-dark;
  }
}
